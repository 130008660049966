import React, { ReactElement } from 'react'
import TopBar from 'src/components/Molecules/TopBar'
import { Container } from '@material-ui/core'
import AppFooter from 'src/components/Molecules/Footer'
import commonStyles from 'src/styles/commonStyles'
import Home from 'src/components/Views/Home'
import Layout from 'src/components/Layout'
import Page from 'src/components/Page'
import PrivateRoute from 'src/components/PrivateRoute'
import { Router } from '@reach/router'
import Loading from 'src/components/Molecules/Loading'
import { navigation } from 'src/utils/navigation'

type LazyLoadComponentArguments = {
  Component: React.ComponentType<any>
}

const LazyLoadComponent = ({ Component, ...props }: LazyLoadComponentArguments) => (
  <React.Suspense fallback={Loading}>
    <Component {...props} />
  </React.Suspense>
)

const DealEditForm = React.lazy(() => import('../components/Views/Deals/EditForm'))
const DealNewForm = React.lazy(() => import('../components/Views/Deals/NewForm'))
const DealDuplicateForm = React.lazy(() => import('../components/Views/Deals/DuplicateForm'))

export default function HomePage(): ReactElement {
  const commonClasses = commonStyles()

  return (
    <>
      <Layout>
        <Page>
          <PrivateRoute path="/login">
            <TopBar />
            <Container component="main" className={commonClasses.mainContentContainer}>
              <Router>
                <Home path="/" />
                <LazyLoadComponent Component={DealNewForm} path={navigation.toDealNew} />
                <LazyLoadComponent Component={DealEditForm} path={`${navigation.toDealEdit}:id`} />
                <LazyLoadComponent
                  Component={DealDuplicateForm}
                  path={`${navigation.toDealDuplicate}:id`}
                />
              </Router>
            </Container>
            <AppFooter />
          </PrivateRoute>
        </Page>
      </Layout>
    </>
  )
}
